<template>
  <div id="province">
    <div class="proContainer">
      <div class="province_row">
        <div class="wrapper">
          <div class="crumb">
            <!-- <input type="button" value="点击一下" @click="test"> -->
            <a style="margin-left: 0px">目的地</a>
            <span style="margin-left:8px">></span>
            <a>中国</a>
            <span style="margin-left:8px">></span>
            <a>四川</a>
            <span style="margin-left:8px">></span>
            <strong style="font-weight: bold; color: #ff7000;margin-left:10px"
              >四川旅游攻略</strong
            >
          </div>
          <div class="title">
            <h2>四川</h2>
            <a href="">(<span style="color: #ff7000">6325705</span>张图片)</a>
            <div class="en">Sichuan Province</div>
          </div>
          <div class="herder_nav">
            <ul>
              <li class="nav_overview">
                <a href="javascipt:;">
                  <i class="nav_icon"></i>
                  <span>首页</span>
                </a>
              </li>
              <li class="nav_line">
                <a href="javascipt:;">
                  <i class="nav_icon"></i>
                  <span>行程线路</span>
                </a>
              </li>
              <li class="nav_mdd">
                <a href="/sceniclist">
                  <i class="nav_icon"></i>
                  <span>景点</span>
                </a>
              </li>
              <li class="nav_hotel">
                <a href="javascipt:;">
                  <i class="nav_icon"></i>
                  <span>酒店</span>
                </a>
              </li>
              <li class="nav_plane">
                <a href="javascipt:;">
                  <i class="nav_icon"></i>
                  <span>机票</span>
                </a>
              </li>
              <li class="nav_localplay">
                <a href="javascipt:;">
                  <i class="nav_icon"></i>
                  <span>当地玩乐</span>
                </a>
              </li>
              <li class="nav_sales">
                <a href="javascipt:;">
                  <i class="nav_icon"></i>
                  <span>旅游度假</span>
                </a>
              </li>
              <li class="nav_community">
                <a href="javascipt:;">
                  <i class="nav_icon"></i>
                  <span>社区</span>
                </a>
              </li>
              <li class="nav_map">
                <a href="javascipt:;">
                  <i class="nav_icon"></i>
                  <span>地图</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rol-line"></div>
      <div class="province_circuit">
        <div class="wrapper">
          <h2 class="p_title">四川17条经典线路</h2>
          <div class="line_card">
            <div class="line_card_left">
              <h3><a href="" class="line_title"><span class="num">1</span>川西稻城亚丁环线7日线路</a></h3>
              <img src="../../assets/destination/地图.png" alt="">
              <h3>
                <a href="" class="describe">
                  <span class="percent">21%</span>
                  初次访问四川的蜂蜂会选择这条线路
                </a>
              </h3>
              <div class="line_direction">
                <a href="">
                  <span>成都</span><span>→</span><span>丹巴</span>
                  <span>→</span><span>新都桥</span><span>→</span>
                  <span>稻城</span><span>→</span><span>亚丁</span><span>→</span>
                  <span>新都桥</span><span>→</span><span>成都</span>
                </a>
                <a href="">查看></a>
              </div>
            </div>
            <div class="line_card_left">
              <h3><a href="" class="line_title"><span class="num">2</span>川西稻城毕棚沟环线7日线路</a></h3>
              <img src="../../assets/destination/地图2.png" alt="">
              <h3>
                <a href="" class="describe">
                  <span class="percent">27%</span>
                  初次访问四川的蜂蜂会选择这条线路
                </a>
              </h3>
              <div class="line_direction">
                <a href="">
                  <span>成都</span><span>→</span><span>雅安</span>
                  <span>→</span><span>泸定</span><span>→</span>
                  <span>康定</span><span>→</span><span>新都桥</span><span>→</span>
                  <span>雅江</span><span>→</span><span>理塘</span>
                  <span>→</span><span>稻城</span><span>→</span><span>理塘</span>
                  <span>→</span><span>新龙县</span><span>→</span><span>甘孜</span>
                </a>
                <a href="">查看></a>
              </div>
            </div>
          </div>
          <div class="line_checkall">
            <a href="">
              <span>查看全部&nbsp;</span>
              <span class="allLine">17条</span>
              <span>&nbsp;经典线路></span>
            </a>
          </div>
        </div>
      </div>
      <div class="rol-line"></div>
      <div class="province_liberty">
        <div class="wrapper">
          <h2>自由行攻略</h2>
          <div class="liberty_pro">
            <ul>
              <li>
                <a href="" class="img"><img src="../../assets/province/liberty1.jpeg" alt=""></a>
                <a href="" class="liberty_title">稻城亚丁怎么去？四季景色有何不同？这篇攻略全告诉你</a>
                <p>最不可错过的景色有哪些？</p>
                <p>一. 稻城县</p>
                <div class="liberty_more">
                  <a href="">查看详情</a>
                </div>
              </li>
              <li>
                <a href="" class="img"><img src="../../assets/province/liberty2.jpeg" alt=""></a>
                <a href="" class="liberty_title">挺进川藏线，如何规划才合理？（附三条经典路线）</a>
                <p>何为川藏线？</p>
                <p>川藏南线和川藏北线有什么区别？</p>
                <div class="liberty_more">
                  <a href="">查看详情</a>
                </div>
              </li>
              <li>
                <a href="" class="img"><img src="../../assets/province/liberty3.jpeg" alt=""></a>
                <a href="" class="liberty_title">稻城亚丁怎么去？四季景色有何不同？这篇攻略全告诉你</a>
                <p>2019最新动态——九寨华丽归来</p>
                <p>①开放区域</p>
                <div class="liberty_more">
                  <a href="">查看详情</a>
                </div>
              </li>
            </ul>
          </div>
          <div class="liberty_all">
            <a href="">查看全部</a>
          </div>
        </div>
      </div>
      <div class="rol-line"></div>
      <div class="province_hotel">
        <div class="wrapper">
          <h2 class="p_title">酒店精选<span>来的游客 大多会关注：</span></h2>
          <div class="liberty_pro">
            <ul>
              <li>
                <a href="">
                  <img src="../../assets/province/hotel1.jpeg" alt="">
                  <div class="img_word">
                    <h2>低价人气客栈</h2>
                    <p>这些九寨沟的人气客栈适合背包客和学生族，这里往往有热情的老板和愉快的氛围，容易认识驴友、把酒...</p>
                  </div>
                </a>
              </li>
              <li>
                <a href="">
                  <img src="../../assets/province/hotel2.jpeg" alt="">
                  <div class="img_word">
                    <h2>度假慢生活</h2>
                    <p>想打卡峨眉山又怕爬山太累，景点太沸？不如来找上一方好住处，在新绿满园中享受佛性的度假慢生活。</p>
                  </div>
                </a>
              </li>
              <li>
                <a href="">
                  <img src="../../assets/province/hotel3.jpeg" alt="">
                  <div class="img_word">
                    <h2>漫步都江堰</h2>
                    <p>都江堰是国内历史悠久的、以无坝引水为特征的宏大水利工程。住景区附近轻松逛景点，交通、吃饭也比...</p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="liberty_all">
            <a href="">查看该地区全部酒店</a>
          </div>
        </div>
      </div>
      <div class="rol-line"></div>
      <div class="province_mall">
        <div class="wrapper">
          <div class="mall_head">
            <h2 class="p_title">旅行商城</h2>
            <ul>
              <li><a href="" class="active">半自助游</a></li>
              <li><a href="">景点门票</a></li>
              <li><a href="">一日游/多日游</a></li>
              <li><a href="">酒店套餐</a></li>
              <li><a href="">交通用车</a></li>
              <li><a href="">当地玩乐</a></li>
            </ul>
          </div>
          <div class="mall_body">
            <div class="mall_list">
              <a href="">
                <div class="mall_img">
                  <img src="../../assets/province/mall_list.jpeg" alt="">
                  <div class="mall_tag">出境半自助游</div>
                </div>
                <div class="mall_content">
                  <h3>全景深度游/春节预售/直飞沙巴7天6晚半自助游（全日逍遥游+亲触长臂猿/观赏萤火虫+国家公园双岛游+体验海上刺激项目+观赏美丽日落+市区游）</h3>
                  <span class="price"><b>￥2799</b>起</span>
                  <span class="sell">新品上架</span>
                </div>
              </a>
            </div>
            <div class="mall_list">
              <a href="">
                <div class="mall_img">
                  <img src="../../assets/province/mall_list.jpeg" alt="">
                  <div class="mall_tag">出境半自助游</div>
                </div>
                <div class="mall_content">
                  <h3>全景深度游/春节预售/直飞沙巴7天6晚半自助游（全日逍遥游+亲触长臂猿/观赏萤火虫+国家公园双岛游+体验海上刺激项目+观赏美丽日落+市区游）</h3>
                  <span class="price"><b>￥3599</b>起</span>
                  <span class="sell">新品上架</span>
                </div>
              </a>
            </div>
          </div>
          <div class="mall_more">
            <a href="">四川还有2个半自助游产品>></a>
          </div>
        </div>
      </div>
      <div class="rol-line"></div>
      <div class="province_travels">
        <div class="wrapper">
          <div class="navbar">
            <span class="on"><a href="">四川游记</a></span>
            <span><a href="">四川推荐行程</a></span>
          </div>
          <div class="content_yj">
            <!-- <div class="navbar_sub">
              <el-dropdown>
                <span class="el-dropdown-link">
                  最新游记<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu>
                  <el-dropdown-item style="background:#eee">最热游记</el-dropdown-item>
                  <el-dropdown-item>最新游记</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown>
                <span class="el-dropdown-link2">
                  按出发时间<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu>
                  <el-dropdown-item style="background:#eee">不限</el-dropdown-item>
                  <el-dropdown-item>1-2</el-dropdown-item>
                  <el-dropdown-item>3-4</el-dropdown-item>
                  <el-dropdown-item>5-6</el-dropdown-item>
                  <el-dropdown-item>7-8</el-dropdown-item>
                  <el-dropdown-item>9-10</el-dropdown-item>
                  <el-dropdown-item>11-12</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown>
                <span class="el-dropdown-link3">
                  按人均花费<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu>
                  <el-dropdown-item style="background:#eee">不限</el-dropdown-item>
                  <el-dropdown-item>1-999</el-dropdown-item>
                  <el-dropdown-item>1K-6K</el-dropdown-item>
                  <el-dropdown-item>6K-20K</el-dropdown-item>
                  <el-dropdown-item>20K以上</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown>
                <span class="el-dropdown-link4">
                  按出行天数<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu>
                  <el-dropdown-item style="background:#eee">不限</el-dropdown-item>
                  <el-dropdown-item>3天以下</el-dropdown-item>
                  <el-dropdown-item>4-7天</el-dropdown-item>
                  <el-dropdown-item>7-15天</el-dropdown-item>
                  <el-dropdown-item>15天以上</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div> -->
            <div class="note_list">
              <div class="note_item" v-for="(item,index) in notes" :key="index">
                <div class="note_img">
                  <a href="">
                    <img :src="base_url+item.sight_img" alt="">
                  </a>
                </div>
                <div class="note_content" @click="noteDetail(item.t_id)">
                  <div class="note_title">
                    <a href="">{{item.notes_title}}</a>
                  </div>
                  <div class="note_center">
                    <a href="">{{item.notes_content}}</a>
                  </div>
                  <div class="note_extra">
                    <span class="note_user">
                      <a href="" style="padding-left:0">
                        <img :src="base_url+item.head_img" alt="">
                      </a>
                      <a href="" class="username">{{item.tourist}}</a>
                    </span>
                    <span class="note_time">{{item.writetime}}</span>
                    <span class="note_views">
                        <i class="el-icon-view"></i>
                        {{item.visible}}
                    </span>
                    <span class="note_ding">
                      {{item.likenum}}
                      <img src="../../assets/province/ding.png" alt="">
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pagebar">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[1, 3, 5, 10]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              ></el-pagination>
            </div>
          </div>
          <div class="content_xc"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "province",
  data() {
    return {
      base_url: "https://backqianlixun.likepoems.com/",
      // accountTableData: 0,
      currentPage: 1, // 当前页
      total: 0, // 数据总条数
      pageSize: 5, // 每页的条数
      notes:{},
      note:[
        {
          title: '来日方长，细水长流。❤️',
          img: require('../../assets/province/note1.jpg'),
          des: "起源于无届，始于于无届，终于无届。爱无届。何为无届？先用创始人的话来解释吧，此处为引用。可知详情，请微信关注公众号“无届”。“无届，不只是吃饭的地方。”“惟德动天，无远弗届，满招损，谦受益，时乃天道。”出自《尚书 大禹谟》，说的是唯有仁厚的德行才能...",
          username: "鱿鱼卷",
          views: '1185/1',
          likes: 13
        },
        {
          title: '《稻城亚丁旅游攻略》人生不可缺少的毕业旅行，我们的夏日旅行《自由行攻略》',
          img: require('../../assets/province/note2.jpg'),
          des: `四年时光，匆匆走过；
              是否像是一场匆匆开始，最终不舍结束的旅程？
              如果说毕业是一场人生的旅程；
              那么，我想用一场别样的旅程来纪念这段人生中不可缺少的环节；
              这个夏天，我们相约 川西 ，相约 稻城 亚丁 ，走过天堂，脚踏地狱.
              关于这次旅行，真的算是一个大写的懵比。...`,
          username: "历巫仰宿关",
          views: '2/0',
          likes: 2
        },
        {
          title: '一切都是最好的安排，最美的风景一直都在路上——记2020年国庆节川西之行',
          img: require('../../assets/province/note3.jpg'),
          des: `写下这些的时候，距离2020年国庆假期已经过去了2个月，今天是2020年12月1日。12月了，不管今年多么的魔幻，在时间面前，终究一切也将进入尾声。虽然已经2个月过去了，但我还是不敢写。因为每当回忆起这次的川西自驾游，所经历之离奇、惊喜、感慨让人很难提笔。但这些离奇、惊喜...`,
          username: "鱿鱼卷",
          views: '1185/1',
          likes: 10
        },
        {
          title: '2021.3达瓦',
          img: require('../../assets/province/note4.jpg'),
          des: "达瓦更扎",
          username: "田小小",
          views: '5/0',
          likes: 1
        },
        {
          title: '童话世界九寨沟，寻觅九寨千古情（附必游景点、住宿指南、行程推荐）',
          img: require('../../assets/province/note5.jpeg'),
          des: "不知是谁说过：“九寨归来不看水。”这句话就像是一个魔咒，始终印在我的脑海里。因为德天独厚的因素，九寨沟我已经去过很多次了。每一次都有新的惊喜发现。岁月流转，不同的季节、不同的旅伴、发生在那里的故事也不相同。",
          username: "历巫仰宿关",
          views: '1235/1',
          likes: 25
        },
        {
          title: '《稻城亚丁白塔旅游包车攻略》我们从全世界路过，四个少女的世界问候',
          img: require('../../assets/province/note6.jpeg'),
          des: `很多人的一生都在选择寻找，而我们，选择去路过。&lt; 我 们 从 全 世 界 路 过 &gt;
                当人过到了30岁后，我们难以摆脱那味道浓厚的烟火气我们从全世界路过
                带着一定需要出发的心揣上找寻勇气和善意的执念
                从 稻城 亚丁 开始四个女生，集结了不同时期的闺蜜友情以...`,
          username: "历巫仰宿关",
          views: '1235/1',
          likes: 16
        },
        {
          title: '《稻城亚丁自由行攻略》走过稻城亚丁的春夏秋冬，在香格里拉的星月轮转《自由行攻略》',
          img: require('../../assets/province/note7.jpeg'),
          des: `俊秀的高山海子。我想告诉你，这里的星辰很深邃来到这里，你感受到的每个季节，
                映入眼帘的每个画面都荟聚着最美的 香格里拉走在吱吱作响的木栈道上，思绪随着稀薄而干爽的空气一同飘向了远方…
                稻城 亚丁回到过去“想回到过去，试着让故事继续，至少不再让你离我而去”...`,
          username: "历巫仰宿关",
          views: '1035/1',
          likes: 18
        },
        {
          title: '《稻城亚丁自由行攻略》孤勇之后 世界尽在眼前《稻城亚丁自由行攻略》',
          img: require('../../assets/province/note8.jpg'),
          des: `前序：时光会老，记忆会模糊，还是照片和文字最可靠，能直白的唤起一些过往经历。
                这篇游记拖了2年，打算弃更的时候却又念念不忘，时常回响起一些片段,我想如果这篇游记停更的话，大概以后不会再写了。还好被兴趣和责任鞭策着，席着几个假期赶了出来。
                两点一线的生活已经足够...`,
          username: "历巫仰宿关",
          views: '1035/1',
          likes: 18
        },
        {
          title: '《稻城亚丁旅游攻略》和闺蜜说走就走的旅行《川西旅行攻略》',
          img: require('../../assets/province/note9.jpg'),
          des: `前言：第一次接触到 稻城 还是在《从你的全世界路过》电影中，那时候就在想原来祖国还有如
                此仙境一般的存在，不愧是蓝色星球上最后一片净土。一直以为是后期调色制作。后来鬼使神差的百度
          找了找相关资料，彻底爱上了这里，便心心念念一直想去。好不容易有空就开始各种查资料，...`,
          username: "历巫仰宿关",
          views: '1035/1',
          likes: 18
        },
        {
          title: '《稻城亚丁自由行攻略》走过稻城亚丁的春夏秋冬，在香格里拉的星月轮转《自由行攻略》',
          img: require('../../assets/province/note10.jpg'),
          des: `俊秀的高山海子。我想告诉你，这里的星辰很深邃来到这里，你感受到的每个季节，
                映入眼帘的每个画面都荟聚着最美的 香格里拉走在吱吱作响的木栈道上，思绪随着稀薄而干爽的空气一同飘向了远方…
                稻城 亚丁回到过去“想回到过去，试着让故事继续，至少不再让你离我而去”...`,
          username: "历巫仰宿关",
          views: '1035/1',
          likes: 18
        },
      ]
    };
  },
  mounted () {
    this.getList();
  },
  methods: {
    getList(){
      // 收集当前页码和每页显示条数
      let pageSize = this.pageSize;
      let currentPage = this.currentPage;
      // let currentState = this.currentState;
      // 发送分页数据请求给后端
      
      this.$axios.get('/provincepage',
        { params:{
            pageSize,
            currentPage,
        }
      })
      .then((res) => {
        this.notes = res.data["data"];
        console.log(this.notes);
        console.log("对应的页码数据",res.data["data"]);
        // 接收后端返回的数据总条数 total 和对应页码的数据data
        let {total,data} = res.data;
        this.total = total;
        // this.accountTableData = data;
        // 如果当前页没有数据且排除第一页
        if(!data.length && this.currentPage !== 1){
          this.currentPage -= 1;
          this.getList();
        }

      }).catch((error) => {
        console.log(error);
      })
    },
    noteDetail(id){
      this.$router.push({path: '/traveldetail',query: { t_id: id }})
    },
    handleSizeChange(val) {
      // 保存每页显示的条数
      this.pageSize = val;
      // 调用分页函数
      this.getList();
    },
    // 当前页码改变 就会触发这个函数
    handleCurrentChange(val) {
      // 保存当前页码
      this.currentPage = val;
      // 调用分页函数
      this.getList();
    },
  }
};
</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
}
.el-dropdown-link:hover:hover,.el-dropdown-link2:hover,.el-dropdown-link3:hover,.el-dropdown-link4:hover{
  color: #ff8a00;
}
.el-dropdown-link2,.el-dropdown-link3,.el-dropdown-link4{
  cursor: pointer;
  margin-left: 30px;
}

el-dropdown-item:hover{
  background-color: #eee;
  color: #ff8a00;
}
.el-icon-arrow-down {
  margin-left: 20px;
}
.proContainer {
  width: 100%;
  /* height: 1000px; */
}
.p_title{
  font-size: 26px;
  color: #333;
  margin-bottom: 35px;
}
.province_row {
  height: 221px;
  margin-top: 4px;
  background-color: #fafafa;
}
.rol-line {
  height: 1px;
  background-color: #e4e4e4;
  overflow: hidden;
}
/* 经典线路 */
.province_circuit {
  height: 549px;
  padding-top: 40px;
  /* background-color: yellowgreen; */
}
.line_card{
  width: 1000px;
  height: 372px;
  /* background-color: #e7788e; */
}
.line_card_left{
  float: left;
  width: 490px;
  height: 372px;
  border: 1px solid #e6e6e6;
}
.line_card_left h3{
  height: 30px;
  margin: 15px 20px;
  line-height: 30px;
  font-size: 20px;
  font-weight: normal;
}
.line_card_left h3 .line_title:hover{
  color: #ff9d00;
}
.line_card_left h3 .line_title{
  font-size: 20px;
}
.line_card_left:nth-of-type(2){
  margin-left: 16px;
}
.line_card_left:nth-of-type(2) .line_direction a:nth-of-type(2){
  margin-left: 40px;
  color: #643718;
}
.line_card_left img{
  width: 490px;
}
.line_card_left .percent{
  color: #ff9d00;
  font-size: 28px;
  margin-right: 12px;
  float: left;
}
.line_card_left .describe{
  height: 30px;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 10px;
}
.line_direction{
  width: 449px;
  height: 64px;
  border-top: 1px dashed #ddd;
  padding-top: 15px;
  margin: 0px 20px;
  font-size: 14px;
  line-height: 24px;
  overflow: hidden;
  position: relative;
}
.line_direction span{
  margin-left: 17px;
}
.line_direction span:nth-of-type(1){
  margin-left: 0px;
}
.line_direction span:nth-of-type(12){
  margin-left: 0px;
}
.line_direction a:nth-of-type(2){
  margin-left: 350px;
  color: #643718;
}
.line_direction a:nth-of-type(2):hover{
  text-decoration: underline;
}
.line_card_left .num{
  float: left;
  margin-right: 15px;
  width: 22px;
  height: 24px;
  background-color: #ff9d00;
  color: #fff;
  text-align: center;
  position: relative;
  line-height: 26px;
}
.line_card_left .num::after{
  content: '';
  position: absolute;
  left: 0;
  bottom: -7px;
  border-left: 11px solid #ff9d00;
  border-right: 11px solid #ff9d00;
  border-bottom: 7px dashed transparent;
  overflow: hidden;
  line-height: 0;
}
.line_checkall{
  clear: both;
  padding-top: 30px;
  text-align: right;
}
.line_checkall a{
  font-size: 18px;
  color: #333;
}
.line_checkall .allLine{
  font-size: 24px;
  color: #ff9d00;
}
/* 自由行攻略 */
.province_liberty {
  height: 442px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #FAFAFA;
}
.province_liberty h2{
  font-size: 26px;
  color: #333;
  margin-bottom: 30px;
}
.liberty_pro{
  height: 304px;
  /* background-color: antiquewhite; */
}
.liberty_pro ul{
  margin-right: -40px;
}
.liberty_pro li{
  float: left;
  width: 310px;
  display: inline;
  margin-right: 35px;
}
.liberty_pro .img{
  display: block;
  margin-bottom: 10px;
  height: 170px;
  overflow: hidden;
  position: relative;
}
.liberty_pro .liberty_title{
  margin-bottom: 5px;
  display: block;
  width: 320px;
  height: 28px;
  line-height: 28px;
  font-size: 18px;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.liberty_pro .liberty_title:hover{
  color: #ff9d00;
  text-decoration: underline;
}
.liberty_pro .liberty_more{
  margin-top: 15px;
  border-top: 1px dashed #ebebeb;
  padding-top: 15px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
.liberty_pro .liberty_more a{
  font-size: 14px;
  color: #ff9d00
}
.liberty_pro .liberty_more a:hover{
  text-decoration: underline;
}
.liberty_all{
  height: 42px;
  margin-top: 25px;
  text-align: center;
}
.liberty_all a{
  display: inline-block;
  width: 142px;
  height: 40px;
  line-height: 40px;
  color: #ff9d00;
  border: 1px solid #ff9d00;
  border-radius: 4px;
}
.liberty_all a:hover{
  color: white;
  background-color: #ff9d00;
}

/* 酒店精选 */
.province_hotel {
  height: 483px;
  padding-top: 40px;
  /* background-color: gold; */
}
.province_hotel .p_title span{
  font-size: 16px;
  color: #666;
  margin-left: 15px;
}
.province_hotel .img_word{
  width: 100%;
  height: 310px;
  position: absolute;
  color: white;
  margin-top: -310px;
  text-align: center;
  background: rgba(0,0,0,0.25);
}
.province_hotel a{
  position: relative;
}
.province_hotel .img_word h2{
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-size: 22px;
  font-weight: bold;
  margin-top: 60px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.province_hotel .img_word p{
  display: inline-block;
  margin-top: 45px;
  margin-left: 40px;
  margin-right: 40px;
  text-shadow: #000 0 1px 0;
  text-overflow: clip;
  white-space: normal;
}
.province_hotel .liberty_all{
  margin-top: 30px;
}
.province_hotel .liberty_all a{
  padding: 0 42px;
}
/* 旅行商城 */
.province_mall {
  height: 410px;
  padding-top: 40px;
  background-color: #fafafa;
}
.province_mall .p_title{
  display: inline;
  float: left;
}
.province_mall .mall_head{
  border-bottom: 1px solid #e6e6e6;
}
.province_mall .mall_head ul{
  width: 800px;
  height: 50px;
  padding-top: 6px;
}
.province_mall .mall_head li{
  line-height: 30px;
  float: left;
  display: inline;
  width: 74px;
}
.province_mall .mall_head li a{
  display: inline-block;
  padding: 0 6px;
  border-radius: 2px;
  margin-right: 5px;
}
.active{
  background-color: #ff8a00;
  color: #fff;
}
.province_mall .mall_head li:nth-of-type(1){
  margin-left: 50px;
}
.province_mall .mall_head li:nth-of-type(3){
  width: 110px;
}
.province_mall .mall_body{
  width: 1000px;
  height: 290px;
  /* background-color: #ff9d00; */
}
.province_mall .mall_body .mall_list{
  width: 238px;
  height: 234px;
  float: left;
  display: inline;
  margin: 0 15px 15px 0;
}
.mall_img{
  position: relative;
  margin-left: -104px;
  margin-top: 16px;
}
.mall_tag{
  position: absolute;
  top: 0;
  padding: 0 7px;
  line-height: 26px;
  background-color: rgba(0,0,0,.7);
  color: #fff;
  font-size: 12px;
}
.mall_content{
  width: 216px;
  height: 80px;
  margin-left: -104px;
  border: 1px solid #f1f1f1;
  padding: 5px 10px 18px;
  transition: height .2s ease-in-out .1s;
}
.mall_list a .mall_content h3:hover{
  transition: height .2s ease-in-out .1s;
}
.mall_content h3{
  height: 40px;
  margin-bottom: 15px;
  font-weight: normal;
  color: #000;
  line-height: 21px;
  overflow: hidden;
  transition: height .2s ease-in-out .1s;
}
.mall_content .price{
  color: #ff8a00;
}
.mall_content .sell{
  float: right;
  padding-top: 12px;
  font-size: 12px;
  color: #666;
}
.mall_content b{
  font-size: 22px;
  font-weight: bold;
}
.mall_more{
  text-align: center;
}
.mall_more a{
  color: #ff9d00;
  font-size: 16px;
}

/* 旅行游记 */
.province_travels {
  height: 2045px;
  padding-top: 40px;
  /* background-color: skyblue; */
}
.province_travels .navbar .on a{
  font-size: 18px;
  color: #333;
}
.province_travels .navbar{
  margin-bottom: 20px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
}
.province_travels .navbar a{
  color: #666;
}
.province_travels .navbar span:nth-of-type(1){
  margin-right: 28px;
}
.province_travels .content_yj{
  width: 1000px;
  display: block;
}
.province_travels .navbar_sub{
  width: 1000px;
  height: 28px;
  margin-bottom: 15px;
}
.province_travels .content_yj .dropdown{
  float: left;
  width: 120px;
  display: inline;
  margin-right: 5px;
  line-height: 28px;
  color: #666;
  position: relative;
}
.province_travels .dropdown_trigger{
  padding: 0 13px;
  height: 28px;
  position: relative;
  z-index: 2;
  line-height: 28px;
  cursor: pointer;
  font-size: 12px;
}
.province_travels .dropdown_trigger i{
  position: absolute;
  top: 6px;
  right: 13px;
  width: 12px;
  height: 6px;
}
.province_travels .dropdown_menu{
  position: absolute;
  top: 0;
  border: 1px solid #d6d6d6;
  padding-top: 26px;
  width: 118px;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
}
/* .dropdown_open{
  border-color: #ff9d00;
  z-index: 5;
}
.moveon{
  background-color: #eee;;
} */
.province_travels .dropdown_menu ul{
  height: 240px;
}
.province_travels .dropdown_menu a{
  display: block;
  padding: 0 12px;
  color: #666;
  cursor: pointer;
}
.province_travels .content_yj .note_title a{
  width: 420px;
  height: 26px;
  display: block;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.province_travels .content_yj .note_title a:hover{
  color: #ff8a00;
}
.province_travels .content_yj .note_list{
  width: 1000px;
}

.province_travels .content_yj .note_item{
  padding: 15px 0;
  height: 150px;
}
.province_travels .content_yj .note_item:nth-of-type(4) .note_content .note_extra{
  margin-top: 20px;
}
.province_travels .content_yj .note_item:nth-of-type(5) .note_content .note_extra{
  margin-top: 20px;
}
.province_travels .content_yj .note_item .note_img{
  float: left;
  width: 220px;
  height: 150px;
  overflow: hidden;
  position: relative;
}
.province_travels .content_yj .note_item .note_img img{
  width: 220px;
  height: 150px;
}
.province_travels .content_yj .note_item .note_content{
  float: right;
  width: 758px;
  height: 150px;
  position: relative;
}
.province_travels .note_content a{
  display: inline-block;
  font-size: 18px;
  margin-bottom: 12px;
}
.province_travels .note_content .note_center a{
  font-size: 14px;
}
.province_travels .note_content .note_extra{
  height: 48px;
  overflow: hidden;
  line-height: 33px;
  margin-top: 20px;
}
.province_travels .note_content .note_extra .username:hover{
  text-decoration: underline;
}
.province_travels .note_content .note_user{
  float: left;
}
.province_travels .note_content .note_user a{
  height: 33px;
  overflow: hidden;
  line-height: 48px;
  font-size: 12px;
  padding-left: 10px;
}
.province_travels .note_content .note_user img{
  border-radius: 16px;
}

.province_travels .note_content .note_views{
  float: left;
  margin-top: 8px;
  margin-left: 14px;
  color: #999;
  font-size: 14px;
}
.province_travels .note_content .note_time{
  float: left;
  margin-top: 8px;
  margin-left: 14px;
  color: #999;
  font-size: 14px;
}
.province_travels .note_content .note_ding{
  float: right;
  margin-top: 8px;
  color: #ff9d00;
  font-size: 14px;
}
.province_travels .note_content .note_ding img{
  z-index: 5;
}
.pagebar{
  float: right;
  margin-top: 40px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #ff8a00;
  color: #fff;
}

.wrapper {
  width: 1000px;
  margin: 0 auto;
}
.crumb {
  height: 20px;
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #666;
  line-height: 20px;
  margin: 0 auto;
}
.crumb a {
  margin-left: 10px;
  height: 20px;
}
.crumb a:hover {
  width: 24px;
  height: 20px;
  color: white;
  background-color: #ff7000;
}
.title h2 {
  display: inline;
  font-size: 40px;
  color: #333;
}
.title a {
  font-size: 14px;
  margin-left: 15px;
}
.en {
  font-size: 20px;
  color: #999;
  margin-top: -10px;
}
.herder_nav {
  margin-top: 13px;
  overflow: hidden;
}
.herder_nav ul li {
  float: left;
  margin-right: 25px;
}
.herder_nav ul li a {
  padding: 20px 0px 23px 0px;
  float: left;
  height: 32px;
}
.herder_nav .nav_icon {
  float: left;
  width: 32px;
  height: 32px;
  margin-right: 6px;
  background: url("~@/assets/scenic/icon-place.png") no-repeat;
  overflow: hidden;
}
.herder_nav ul li a span {
  line-height: 32px;
}
.herder_nav ul li {
  border-bottom: #fafafa solid 3px;
}
.nav_overview .nav_icon {
  background-position: 0 0;
}
.nav_overview:hover {
  border-bottom: #aea181 solid 3px;
}
.nav_line .nav_icon {
  background-position: 0 -40px;
}
.nav_line:hover {
  border-bottom: #74b9f3 solid 3px;
}
.nav_mdd .nav_icon {
  background-position: 0 -80px;
}
.nav_mdd:hover {
  border-bottom: #85c65e solid 3px;
}
.nav_hotel .nav_icon {
  background-position: 0 -120px;
}
.nav_hotel:hover {
  border-bottom: #0a89e4 solid 3px;
}
.nav_plane .nav_icon {
  background-position: 0 -160px;
}
.nav_plane:hover {
  border-bottom: #0a89e4 solid 3px;
}
.nav_localplay .nav_icon {
  background-position: 0 -200px;
}
.nav_localplay:hover {
  border-bottom: #bada25 solid 3px;
}
.nav_sales .nav_icon {
  background-position: 0 -240px;
}
.nav_sales:hover {
  border-bottom: #e7788e solid 3px;
}
.nav_community .nav_icon {
  background-position: 0 -280px;
}
.nav_community:hover {
  border-bottom: #ff9d00 solid 3px;
}
.nav_map .nav_icon {
  background-position: 0 -320px;
}
.nav_map:hover {
  border-bottom: #63c799 solid 3px;
}
</style>