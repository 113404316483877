<template>
  <div class="myProfileContainer">
    <h1>我的窝</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.myProfileContainer{
  width: 100%;
  height: 440px;
}
.myProfileContainer h1{
  font-size: 45px;
  font-weight: 800;
}
</style>